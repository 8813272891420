import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MemberCasesApiService } from 'carehub-api/membercases-api.service';
import { Member } from 'carehub-api/models/member/member';
import { MemberCase } from 'carehub-api/models/membercase/membercase';
import { ColumnDetails } from 'carehub-root/shared/components/controls/smartlist-grid/column-details';
import { LookupService } from 'carehub-root/shared/services/lookup.service';
import { LookupTypes } from 'carehub-root/shared/services/models/lookup-types.enum';
import { FilterableComponent } from 'carehub-shared/components/filterable-component';
import { SmartListCriteria } from 'carehub-shared/smartlist';
import { of } from 'rxjs';

@Component({
  selector: 'ch-case-selection',
  templateUrl: './case-selection.component.html',
  styleUrls: ['./case-selection.component.scss'],
})
export class CaseSelectionComponent
  extends FilterableComponent<null, MemberCase>
  implements OnInit
{
  private _member: Member = null;

  @Output() caseSelected = new EventEmitter<MemberCase>();

  @Input() set member(member: Member) {
    this._member = member;
    this.loadData();
  }
  get member() {
    return this._member;
  }

  includedColumns: ColumnDetails[] = [
    {
      columnDef: 'caseOpenedDate',
      sortable: true,
      width: '2fr',
      header: 'Opened Date',
      cell: (row: MemberCase) =>
        row.caseOpenedDate
          ? formatDate(`${row.caseOpenedDate}`, 'mediumDate', 'en-US')
          : '',
    },
    {
      columnDef: 'caseNumber',
      sortable: true,
      width: '3fr',
      header: 'Case Number',
      cell: (row: MemberCase) => (row.caseNumber ? `${row.caseNumber}` : ''),
    },
    {
      columnDef: 'caseStatusId',
      sortable: true,
      width: '2fr',
      header: 'Status',
      cell: (row: MemberCase) =>
        this.lookupService.getDescriptionByTypeAndId(
          LookupTypes.CaseStatuses,
          row.caseStatusId
        ),
    },
    {
      columnDef: 'productId',
      sortable: true,
      width: '1.5fr',
      header: 'Product',
      cell: (row: MemberCase) =>
        this.lookupService.getDescriptionByTypeAndId(
          LookupTypes.Products,
          row.productId
        ),
    },
    {
      columnDef: 'requestedProcedureItemCategoryId',
      sortable: true,
      width: '2fr',
      header: 'Procedure Category',
      cell: (row: MemberCase) =>
        this.lookupService.getDescriptionByTypeAndId(
          LookupTypes.ProcedureItemCategories,
          row.requestedProcedureItemCategoryId
        ),
    },
    {
      columnDef: 'requestedProcedureItemId',
      sortable: true,
      width: '2fr',
      header: 'Procedure Item',
      cell: (row: MemberCase) =>
        this.lookupService.getDescriptionByTypeAndId(
          LookupTypes.ProcedureItems,
          row.requestedProcedureItemId
        ),
    },
    {
      columnDef: 'caseClosedDate',
      sortable: true,
      width: '2fr',
      header: 'Closed Date',
      cell: (row: MemberCase) =>
        row.caseClosedDate
          ? formatDate(`${row.caseClosedDate}`, 'mediumDate', 'en-US')
          : 'N/A',
    },
  ];

  constructor(
    private lookupService: LookupService,
    private memberCasesApiService: MemberCasesApiService
  ) {
    super({ pageSize: 10, sortField: 'createdDate', sortDirection: 'asc' });
  }

  filterMethod(criteria: SmartListCriteria, filter: null) {
    if (this.member)
      return this.memberCasesApiService.getCasesForMember(this.member.memberId);
    return of(null);
  }

  onRowClick(event: MemberCase) {
    this.caseSelected.emit(event);
  }
}
