import { Component, Input, OnInit } from '@angular/core';
import { ScrollService } from 'carehub-shared/services/scroll.service';
@Component({
  selector: 'ch-side-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() menuItems: {
    displayName: string;
    routeName: string;
    disabled: () => boolean;
  }[];
  constructor(private scrollService: ScrollService) {}

  ngOnInit() {
    this.scrollService.scrollToTop$.subscribe(() => {
      let matSideNav = document.querySelector('.mat-sidenav-content');
      if (matSideNav) {
        matSideNav.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  getClasses(menuItem: any): Object {
    return {
      'btn-disabled': menuItem.disabled ? menuItem.disabled() : false,
    };
  }
}
