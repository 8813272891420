import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  scrollToTop$: EventEmitter<void> = new EventEmitter<void>();

  scrollToTop() {
    this.scrollToTop$.emit();
  }
}
