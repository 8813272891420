import { SelectionModel } from '@angular/cdk/collections';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Store, select } from '@ngrx/store';
import {
  PermissionScopes,
  checkPermission,
} from 'carehub-root/shared/directives/if-allowed.directive';
import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-shared/components/base-component';
import {
  ClickGroup,
  ClickGroupType,
  DoubleClickManager,
} from 'carehub-shared/double-click-manager';
import { SmartListCriteria, SmartListResult } from 'carehub-shared/smartlist';
import * as fromShared from 'carehub-shared/state/index';
import { User } from 'carehub-shared/state/shared.reducer';
import { Utils } from 'carehub-shared/utils';
import { isNumber } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { IconDetails } from '../icon-details';
import { ColumnDetails, ColumnTextAlign } from './column-details';
import { RowDetails } from './row-details';

/** the type of grid to render. affects click and select behavior */
export enum SelectableType {
  /** default: clicking the row executes the callback (generally a navigation event) */
  None = 'None',
  SingleRow = 'SingleRow',
  SingleRowCheckbox = 'SingleRowCheckbox',
  Checkbox = 'Checkbox',
}
/** behavior enum for click on a multi-select capable grid */
export enum MultiSelectClickMode {
  /** default: clicks should select the specific row and invoke the callback, if any */
  Default = 'Default',
  /** on single click select, on double click invoke the appropriate callback */
  SelectAndCallback = 'SelectAndCallback',
}

export interface GridHandlers<TDomainObject> {
  allowSelection?(
    existingSelections: TDomainObject[],
    newlySelected: TDomainObject
  ): boolean;
}

@Component({
  selector: 'ch-smartlist-grid',
  templateUrl: './smartlist-grid.component.html',
  styleUrls: ['./smartlist-grid.component.scss'],
  // todo(perf): this could spare some burning CPUs, but I'm scared to hotfix it
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartListGridComponent<TDomainObject>
  extends BaseComponent
  implements OnInit
{
  @Output() rowClick = new EventEmitter<TDomainObject>();
  @Output() selectionsChanged = new EventEmitter<TDomainObject[]>();
  @Output() smartListSet = new EventEmitter<SmartListResult<TDomainObject>>();
  @Output() page = new EventEmitter<{ pageIndex: number; pageSize: number }>();
  @Output() sort = new EventEmitter<{
    sortField: string;
    sortDirection: string;
  }>();
  // todo(refactor): this load event does nothing. referenced in a few places, remove me
  @Output() load = new EventEmitter<SmartListCriteria>();
  @Output() addClick = new EventEmitter<void>();
  @Output() deleteClick = new EventEmitter<void>();
  @Output() checkboxClick = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();

  @Input() extraLarge = false;
  @Input() infiniteScroll = false;
  @Input() hideHeader = false;
  @Input() freezeSelection = false;
  @Input() disableGlobalSearch = false;
  @Input() gridTitle: string;
  @Input() permissionName: string;
  @Input() canViewScope: PermissionScopes = PermissionScopes.READ;
  @Input() includedColumns: ColumnDetails[];
  @Input() rowCssResolver: RowDetails;
  @Input() disableAdd = false;
  @Input() disableDelete = false;
  @Input() removePadding = false;
  @Input() selectableType = SelectableType.None;
  @Input() multiSelectClickMode = MultiSelectClickMode.Default;
  @Input() idFieldName: keyof TDomainObject = null;
  @Input() allowSelectAll = false;
  @Input() clearSelectionOnPage = false;
  @Input() disableSort = false;
  @Input() showPaginator = true;
  @Input() smartListCriteria: SmartListCriteria;
  @Input() handlers: GridHandlers<TDomainObject>;
  @Input() icons: IconDetails[] = [];
  @Input() disabled: boolean;
  @Input() pageSizeOptions = [10, 25, 50, 100];
  @Input() useV2Branding: boolean;
  // this child is dynamic
  @ViewChild('gridPaginator') paginator: MatPaginator;
  ColumnTextAlign = ColumnTextAlign; // make enum accessible in view

  currentUser: User;
  smartListFound = false;

  /** last selected row */
  private lastSelection: TDomainObject = null;

  @Input() isLoadingInput: boolean = null;
  private isLoading = false;
  private _displayedColumns: string[];

  @Input() set displayedColumns(value: string[]) {
    this._displayedColumns = value;
  }

  get isReadonly(): boolean {
    return this.disabled;
  }

  get displayedColumns(): string[] {
    if (this._displayedColumns) {
      return this._displayedColumns;
    } else if (this.includedColumns) {
      return this.includedColumns.map((c) => c.columnDef);
    } else {
      return [];
    }
  }

  // used by the cell function sometimes
  get totalRows(): number {
    if (this.infiniteScroll) {
      return Number.POSITIVE_INFINITY;
    }
    return this.smartListResultObject && this.smartListResultObject.rowCount;
  }

  private _smartListResult: SmartListResult<TDomainObject>;

  @Input() set smartListResult(
    value: SmartListResult<TDomainObject> | TDomainObject[]
  ) {
    if (value instanceof Array) {
      this._smartListResult = {
        currentPage: 1,
        pageCount: 1,
        pageSize: value.length,
        results: value,
        rowCount: value.length,
        filterHashCode: undefined,
      };
    } else {
      this._smartListResult = value;
      if (this._smartListResult) {
        this.smartListFound = true;
      }
    }

    if (this._smartListResult) {
      this.datasource = this._smartListResult.results;

      if (this.paginator) {
        // this is 0-based
        this.paginator.pageIndex = this._smartListResult.currentPage - 1;
      }

      if (this.smartListCriteria) {
        this.smartListCriteria.cachedFilterHashCode =
          this._smartListResult.filterHashCode;
        this.smartListCriteria.cachedRowCount = this._smartListResult.rowCount;
      }
    } else {
      this.datasource = [];
      this.paginator && this.paginator.firstPage();
    }

    this.smartListSet.emit(this._smartListResult);
    this.isLoading = false;
    if (this.clearSelectionOnPage) {
      this.removeAllSelections();
    }
  }

  get smartListResultObject(): SmartListResult<TDomainObject> {
    if (!this.isReadingAllowed) {
      return {
        currentPage: 1,
        pageCount: 1,
        pageSize: 0,
        results: [],
        rowCount: 0,
        filterHashCode: undefined,
      };
    }

    return this._smartListResult;
  }

  get progressMode() {
    return this.isLoadingInput ||
      this.isLoading ||
      !this.smartListResultObject ||
      this.smartListResultObject.isLoading
      ? 'indeterminate'
      : 'determinate';
  }

  private _datasource: TDomainObject[];

  @Input() set datasource(items: TDomainObject[]) {
    this._datasource = items;
    this.lastSelection = null;
  }

  get datasource(): TDomainObject[] {
    if (!this.isReadingAllowed) {
      return [];
    }

    return this._datasource;
  }

  get datasourceLength(): number {
    if (!this.isReadingAllowed || !this.datasource) {
      return 0;
    }

    return this.datasource.length;
  }

  selection = new SelectionModel<TDomainObject>(true, []);

  protected rowClickManager = new DoubleClickManager(this.unsubscribe$);

  constructor(protected store: Store<fromRoot.State>) {
    super();
    // this keeps the current selection synchronized
    // between pages based on the idFieldNames, not object identity
    this.selection.isSelected = this.isChecked.bind(this);
  }

  ngOnInit() {
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(fromShared.getCurrentUser))
      .subscribe((user) => {
        this.currentUser = user;
      });

    if (
      this.selectableType === SelectableType.Checkbox ||
      this.selectableType === SelectableType.SingleRowCheckbox
    ) {
      this.displayedColumns = ['__select', ...this.displayedColumns];
    }

    this.rowClickManager.clicks$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((e: ClickGroup<TDomainObject>) => this.handleRowClick(e));
  }

  onCheckBoxClicked(event: any, row: any, column: any) {
    if (event) {
      this.checkboxClick?.emit({
        checked: event,
        row: row,
      });

      if (row && column && column.controlDef && column.controlDef.setValue) {
        column.controlDef.setValue(row, event);
        const rowSelect = this.selection.selected.findIndex(
          (d) => d[this.idFieldName] === row[this.idFieldName]
        );
        (<any>this.selection.selected[rowSelect])[column.controlDef.name] =
          event.checked;
        this.selectionsChanged.emit(this.selection.selected);
      }
    }
  }

  onBlur(event: any) {
    this.blur.emit(event);
  }

  onChange(event: any) {
    this.change.emit(event);
  }
  determineLink() {
    let result = null;

    if (this.addClick.observers.length === 0) {
      result = './00000000-0000-0000-0000-000000000000';
    }

    return result;
  }

  refresh() {
    if (this._smartListResult) {
      this.datasource = [...this._smartListResult.results];
    } else {
      return;
    }
  }

  get isReadingAllowed(): boolean {
    // short circuit this if there is no permissionName
    // specified.
    if (!this.permissionName) {
      return true;
    }

    const result = checkPermission(
      this.permissionName,
      this.canViewScope &&
        Object.values(PermissionScopes).includes(this.canViewScope)
        ? this.canViewScope
        : PermissionScopes.READ,
      this.currentUser
    );
    return result;
  }

  canDelete(): boolean {
    if (!this.deleteClick.observers || this.deleteClick.observers.length == 0) {
      return false;
    }

    return checkPermission(
      this.permissionName,
      PermissionScopes.DELETE,
      this.currentUser
    );
  }

  onAdd(event: any) {
    this.addClick.emit();
  }

  onDelete(event: any) {
    this.deleteClick.emit();
  }

  onRowCheckChange(event: MatCheckboxChange, item: TDomainObject) {
    if (
      this.selectableType === SelectableType.SingleRow ||
      this.selectableType === SelectableType.SingleRowCheckbox
    ) {
      this.removeAllSelections();
    }
    if (event) {
      if (!this.toggleItem(item)) {
        event.source.checked = false;
      }
    }
  }

  onSortChange(sort: Sort) {
    if (this.isReadingAllowed) {
      this.isLoading = true;
      this.sort.emit({ sortDirection: sort.direction, sortField: sort.active });
    }
  }

  onPage(pageEvent: PageEvent) {
    if (this.isReadingAllowed) {
      this.isLoading = true;
      this.page.emit({
        pageIndex: pageEvent.pageIndex,
        pageSize: pageEvent.pageSize,
      });
    }
  }
  /** row click callback. May either propagate to callback, or toggle grid item. */
  onRowClick(item: TDomainObject, event: MouseEvent) {
    this.rowClickManager.click(event, item);
  }
  private isToggleMode(click: ClickGroup<TDomainObject>): boolean {
    return (
      this.idFieldName &&
      (this.selectableType !== SelectableType.Checkbox ||
        click.type === ClickGroupType.Single ||
        this.multiSelectClickMode === MultiSelectClickMode.Default)
    );
  }
  private isCallbackMode(click: ClickGroup<TDomainObject>): boolean {
    return (
      this.isReadingAllowed &&
      (this.selectableType !== SelectableType.Checkbox ||
        click.type === ClickGroupType.Double ||
        this.multiSelectClickMode === MultiSelectClickMode.Default)
    );
  }
  protected handleRowClick(click: ClickGroup<TDomainObject>) {
    if (!this.freezeSelection) {
      if (
        this.selectableType === SelectableType.SingleRow ||
        this.selectableType === SelectableType.SingleRowCheckbox
      ) {
        this.removeAllSelections();
      }
      if (this.isToggleMode(click)) {
        this.toggleRowClicked(click.data, click.click);
      }
      if (this.isCallbackMode(click)) {
        this.rowClick.emit(click.data);
      }
    }
  }
  /** handles row clicks by toggling the selected row. supports holding 'shift' to select all intermediate rows from last selected */
  private toggleRowClicked(item: TDomainObject, event: MouseEvent) {
    // shift key behavior: selects intermediate items (multi-select only)
    this.toggleItem(item);
    if (
      this.isSelected(item) &&
      this.selectableType === SelectableType.Checkbox &&
      event.shiftKey &&
      this.lastSelection
    ) {
      const indexes = [
        this.datasource.indexOf(item),
        this.datasource.indexOf(this.lastSelection),
      ];
      if (indexes.filter((x) => isNumber(x)).length === 2) {
        const start = indexes[0] < indexes[1] ? indexes[0] : indexes[1];
        const end = indexes[0] > indexes[1] ? indexes[0] : indexes[1];
        this.datasource
          .filter((obj, index) => start <= index && index <= end)
          .forEach((x: TDomainObject) => {
            if (!this.isSelected(x)) {
              this.toggleItem(x);
            }
          });
      }
    }
    if (this.isSelected(item)) {
      this.lastSelection = item;
    } else if (this.lastSelection === item) {
      this.lastSelection = null;
    }

    Utils.clearSelection();
  }

  getExtraIcons(): IconDetails[] {
    return this.icons
      ? this.icons.filter((x) => !x.render || x.render()).reverse()
      : [];
  }

  onIconClick(event: any, rowValue: any, iconDetails: IconDetails) {
    if (iconDetails) {
      event.preventDefault();
      event.stopPropagation();

      if (iconDetails.urlFieldName && rowValue[iconDetails.urlFieldName]) {
        let url = '';
        if (!/^http[s]?:\/\//.test(rowValue[iconDetails.urlFieldName])) {
          url += 'http://';
        }

        url += rowValue[iconDetails.urlFieldName];
        // todo(security): should probably be 'noopener,noreferrer'
        // since we do not verify this url before opening
        window.open(url, '_blank');
      }

      if (iconDetails.click) {
        iconDetails.click(rowValue);
      }
    }
  }

  onMasterToggle() {
    if (this._smartListResult) {
      this.isAllSelected()
        ? this.removeAllSelections()
        : this._smartListResult.results.forEach((row: TDomainObject) =>
            this.selection.select(row)
          );
      this.lastSelection = null;
      if (this.isReadingAllowed) {
        this.selectionsChanged.emit(this.selection.selected);
      }
    }
  }

  private isSelected(item: TDomainObject): boolean {
    return (
      this.selection.selected.find((selected) => item === selected) != null
    );
  }

  toggleSelectionByIndex(index: number): any {
    if (index >= this._smartListResult.results.length) {
      index = this._smartListResult.results.length - 1;
    } else if (index < 0) {
      index = 0;
    }
    this.toggleItem(this._smartListResult.results[index]);
  }

  removeSelection(item: any) {
    const selectedItems = this.getSelected();
    if (
      selectedItems.find(
        (c: any) => c[this.idFieldName] === item[this.idFieldName]
      )
    ) {
      this.toggleItem(item);
    }
  }

  /** clears all selections and selection context */
  removeAllSelections() {
    this.selection.clear();
    this.lastSelection = null;
  }

  /**
   * returns the selected rows from the grid view
   * @param options (Optional): the options. note that orderByVisible will only return items shown in the grid
   */
  getSelected(options?: Partial<{ orderByVisible: boolean }>): TDomainObject[] {
    options = { orderByVisible: false, ...options };
    const selected = this.selection.selected;
    return options.orderByVisible
      ? // filter preserves order, so this will sort selected based on the order shown
        this._datasource.filter((row) =>
          selected.find((checked) =>
            this.idFieldName
              ? checked[this.idFieldName] === row[this.idFieldName]
              : checked === row
          )
        )
      : selected;
  }

  moveSelection(from: number, to: number): any {
    moveItemInArray(this.selection.selected, from, to);
    // We go through this convoluted logic to ensure the order of the items is properly persisted.
    // Without this, when another selection/deselection occurs, the order is reverted back.
    const clone = [...this.selection.selected];
    this.removeAllSelections();
    for (const item of clone) {
      this.selection.toggle(item);
    }

    if (this.isReadingAllowed) {
      this.selectionsChanged.emit(this.selection.selected);
    }
  }

  getIcon(index: number, column: any, iconEntry: any): IconDetails {
    let iconDetails = column.iconSets[index][iconEntry];
    if (!iconDetails) {
      iconDetails = column.iconSets[index]['default'];
    }
    return iconDetails;
  }

  isActive(row: { inactiveDate: string }): boolean {
    return !(row.inactiveDate && Date.parse(row.inactiveDate) < Date.now());
  }

  getCellValueIcons(cellValue: any) {
    return cellValue && cellValue.icons;
  }

  // the real wtf here is that we dynamically dispatch
  // the column type based on the actual value in the col
  // instead of binding that up front.
  typeOf(column: ColumnDetails, val: any): string {
    // !important: the control type should short-circuit
    // value type checking.

    if (column.type == 'control') {
      return 'control';
    }

    if (val instanceof String || typeof val === 'string') {
      return 'string';
    }

    if (column && column.iconSets) {
      return 'icons';
    }

    if (val instanceof Number) {
      return 'number';
    }

    if (val instanceof Array) {
      return 'array';
    }

    if (val && val.pipe) {
      return 'observable';
    }

    return 'string';
  }

  checkboxLabel(row?: TDomainObject): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  isAllSelected() {
    if (!this._smartListResult) {
      return false;
    }

    const numSelected = this.selection.selected.length;
    const numRows = this._smartListResult.results.length;
    return numSelected === numRows;
  }

  retoggleAllItems(): boolean {
    this.selection.selected.forEach((rec) => {
      this.toggleItem(rec);
    });
    return true;
  }

  toggleItem(item: TDomainObject): boolean {
    // We need to find the exact row to toggle for the case where it's being removed from selection.
    // After a paging or sorting operation the list will contains objects with different memory addresses
    // so we need to find the correct one by its Id.
    const findById = (existingRow: any) => {
      return existingRow[this.idFieldName] === (<any>item)[this.idFieldName];
    };

    let foundItem = this.selection.selected.find(findById);
    if (!foundItem) {
      // If it hasn't been selected then it wouldn't be in the collection above
      //  so just toggle the one past in.
      foundItem = item;
    }

    let allowSelection = true;

    if (this.isChecked(item) === false) {
      if (this.handlers && this.handlers.allowSelection) {
        if (!this.handlers.allowSelection(this.selection.selected, item)) {
          allowSelection = false;
        }
      }
    }

    if (allowSelection) {
      this.selection.toggle(foundItem);
      if (this.isReadingAllowed) {
        this.selectionsChanged.emit(this.selection.selected);
      }
    }

    return allowSelection;
  }

  isAnyChecked(): boolean {
    let result = false;
    if (
      this.selection &&
      this.selection.selected &&
      this.selection.selected.length > 0
    ) {
      result = true;
    }

    return result;
  }

  private isChecked(row: any): boolean {
    if (!this.idFieldName) {
      throw Error(
        `Please provide an 'idFieldName' on your SmartList to enable the checkboxes to be synched properly`
      );
    }

    return this.selection.selected.some(
      (selectedRow: any) =>
        selectedRow[this.idFieldName] === row[this.idFieldName]
    );
  }

  protected onDestroy() {
    // the base associator sometimes sets these to null, therefore the nullcheck before any 'complete' or 'dispose' calls.
    this.rowClickManager?.dispose();
    this.rowClick?.complete();
    this.selectionsChanged?.complete();
    this.smartListSet?.complete();
    this.page?.complete();
    this.sort?.complete();
    this.load?.complete();
    this.addClick?.complete();
    this.deleteClick?.complete();
    this.checkboxClick?.complete();
  }
}
