import { Action } from '@ngrx/store';
import { ClientName } from 'carehub-api/models/client/clientname';
import { Cpt } from 'carehub-api/models/common/cpt';
import { DescribedItemLookup } from 'carehub-api/models/common/describeditemlookup';
import { Drg } from 'carehub-api/models/common/drg';
import { Hcpcs } from 'carehub-api/models/common/hcpcs';
import { MemberSearch } from 'carehub-api/models/member/membersearch';
import { SecurityUser } from 'carehub-root/rest-services/securityApi/models/securityuser';
import { Procedure } from 'carehub-shared/components/controls/procedure';
import { Guid } from 'carehub-shared/guid';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';
import { User } from 'oidc-client';
import {
  HeaderOptions,
  LookupItem,
  MemberSearchCriteria,
} from './shared.reducer';

export enum SharedActionTypes {
  NoOp = '[Shared] No Operation',

  SetBusyStatus = '[Shared] Set Busy Status',
  SetCurrentError = '[Shared] Set Error',
  SetCurrentMessage = '[Shared] Set Message',

  SetCurrentBusinessModuleId = '[Shared] Set Current Business Module Id',
  SetCurrentBusinessModuleIdSuccess = '[Shared] Set Current Business Module Id Success',

  Login = '[Shared] Login',
  LoginSuccess = '[Shared] Login Success',
  LoginFail = '[Shared] Login Fail',
  LoginFailRedirect = '[Shared] Logout Fail Redirected',
  SetSecurityInfo = '[Shared] Set Security Info',
  ClearUser = '[Shared] Clear User',

  Logout = '[Shared] Logout',
  LogoutStarted = '[Shared] Logout Started',
  LogoutSuccess = '[Shared] Logout Success',
  LogoutFail = '[Shared] Logout Fail',

  LoadLookups = '[Shared] Load Lookups',
  LoadLookupsSuccess = '[Shared] Load Lookups Success',

  LoadAllLookups = '[Shared] Load All Lookups',
  LoadAllLookupsSuccess = '[Shared] Load All Lookups Success',

  LoadCptLookups = '[Shared] Load Cpt Lookups',
  LoadCptLookupsSuccess = '[Shared] Load Cpt Lookups Success',

  LoadDrgLookups = '[Shared] Load Drg Lookups',
  LoadDrgLookupsSuccess = '[Shared] Load Drg Lookups Success',

  LoadHcpcsLookups = '[Shared] Load Hcpcs Lookups',
  LoadHcpcsLookupsSuccess = '[Shared] Load Hcpcs Lookups Success',

  SetMemberSearchClientId = '[Shared] Set Member Search Client Id',
  SetMemberSearchNameFilter = '[Shared] Set Member Search Name Filter',

  FindMembers = '[Shared] Find Members',
  FindMembersSuccess = '[Shared] Find Members Success',

  LoadClientNames = '[Shared] Get Client Names',
  LoadClientNamesSuccess = '[Shared] Get Client Names Success',

  GetHeaderOptions = '[Shared] Get Header Options',
  SetHeaderOptions = '[Shared] Set Header Options',

  LoadCategoryProcedures = '[Shared] Load Category Procedures',
  LoadCategoryProcedureSuccess = '[Shared] Load Category Procedure Success',
  ClearCategoryProcedures = '[Shared] Clear Category Procedures',
}

export class GetHeaderOptions implements Action {
  readonly type = SharedActionTypes.GetHeaderOptions;

  constructor() {}
}

export class SetHeaderOptions implements Action {
  readonly type = SharedActionTypes.SetHeaderOptions;

  constructor(public headerOptions: Partial<HeaderOptions>) {}
}

export class NoOp implements Action {
  readonly type = SharedActionTypes.NoOp;
}

export class SetBusyStatus implements Action {
  readonly type = SharedActionTypes.SetBusyStatus;

  constructor(public payload: boolean) {}
}

export class SetCurrentError implements Action {
  readonly type = SharedActionTypes.SetCurrentError;

  constructor(public payload: any) {}
}

export class SetCurrentMessage implements Action {
  readonly type = SharedActionTypes.SetCurrentMessage;

  // shows a global green snackbar for the supplied duration, hooked up in app.component.ts
  constructor(public text: string, public duration: number = 3500) {}
}

export class SetCurrentBusinessModuleId implements Action {
  readonly type = SharedActionTypes.SetCurrentBusinessModuleId;

  constructor(public payload: number) {}
}

export class SetCurrentBusinessModuleIdSuccess implements Action {
  readonly type = SharedActionTypes.SetCurrentBusinessModuleIdSuccess;

  constructor(public payload: number) {}
}

export class Login implements Action {
  readonly type = SharedActionTypes.Login;

  constructor() {}
}

export class LoginSuccess implements Action {
  readonly type = SharedActionTypes.LoginSuccess;

  constructor(public user: User) {}
}

export class SetSecurityInfo implements Action {
  readonly type = SharedActionTypes.SetSecurityInfo;

  constructor(public securityInfo: SecurityUser) {}
}

export class LoginFail implements Action {
  readonly type = SharedActionTypes.LoginFail;

  constructor(public payload: string) {}
}

export class LoginFailRedirect implements Action {
  readonly type = SharedActionTypes.LoginFailRedirect;

  constructor(public payload: any) {}
}

export class Logout implements Action {
  public constructor() {}
  readonly type = SharedActionTypes.Logout;
}

export class ClearUser implements Action {
  readonly type = SharedActionTypes.ClearUser;
}

export class LogoutStarted implements Action {
  readonly type = SharedActionTypes.LogoutStarted;
}

export class LogoutSuccess implements Action {
  readonly type = SharedActionTypes.LogoutSuccess;
}

export class LogoutFail implements Action {
  readonly type = SharedActionTypes.LogoutFail;

  constructor(public payload: string) {}
}

export class LoadCptLookups implements Action {
  readonly type = SharedActionTypes.LoadCptLookups;

  constructor(public code: string) {}
}

export class LoadCptLookupsSuccess implements Action {
  readonly type = SharedActionTypes.LoadCptLookupsSuccess;

  constructor(public payload: { code: string; data: Cpt }) {}
}

export class LoadDrgLookups implements Action {
  readonly type = SharedActionTypes.LoadDrgLookups;

  constructor(public code: string) {}
}

export class LoadDrgLookupsSuccess implements Action {
  readonly type = SharedActionTypes.LoadDrgLookupsSuccess;

  constructor(public payload: { code: string; data: Drg }) {}
}

export class LoadHcpcsLookups implements Action {
  readonly type = SharedActionTypes.LoadHcpcsLookups;

  constructor(public code: string) {}
}

export class LoadHcpcsLookupsSuccess implements Action {
  readonly type = SharedActionTypes.LoadHcpcsLookupsSuccess;

  constructor(public payload: { code: string; data: Hcpcs }) {}
}

export class LoadLookups implements Action {
  readonly type = SharedActionTypes.LoadLookups;

  constructor(public payload: LookupTypes) {}
}

export class LoadAllLookups implements Action {
  readonly type = SharedActionTypes.LoadAllLookups;
}

export class LoadLookupsSuccess implements Action {
  readonly type = SharedActionTypes.LoadLookupsSuccess;

  constructor(public payload: { type: string; data: LookupItem[] }) {}
}

export class LoadAllLookupsSuccess implements Action {
  readonly type = SharedActionTypes.LoadAllLookupsSuccess;

  constructor(public payload: DescribedItemLookup) {}
}

export class SetMemberSearchClientId implements Action {
  readonly type = SharedActionTypes.SetMemberSearchClientId;

  constructor(public clientId: string) {}
}

export class SetMemberSearchNameFilter implements Action {
  readonly type = SharedActionTypes.SetMemberSearchNameFilter;

  constructor(public payload: string) {}
}

export class FindMembers implements Action {
  readonly type = SharedActionTypes.FindMembers;

  constructor(public payload: MemberSearchCriteria) {}
}

export class FindMembersSuccess implements Action {
  readonly type = SharedActionTypes.FindMembersSuccess;

  constructor(public results: MemberSearch[]) {}
}

export class LoadClientNames implements Action {
  readonly type = SharedActionTypes.LoadClientNames;

  constructor() {}
}

export class LoadClientNamesSuccess implements Action {
  readonly type = SharedActionTypes.LoadClientNamesSuccess;

  constructor(public payload: ClientName[]) {}
}

export class LoadCategoryProcedures implements Action {
  readonly type = SharedActionTypes.LoadCategoryProcedures;

  constructor(public payload: { categoryId: number; memberId: Guid }) {}
}

export class LoadCategoryProcedureSuccess implements Action {
  readonly type = SharedActionTypes.LoadCategoryProcedureSuccess;

  constructor(public payload: Procedure[]) {}
}

export class ClearCategoryProcedures implements Action {
  readonly type = SharedActionTypes.ClearCategoryProcedures;

  constructor() {}
}

export type SharedActions =
  | GetHeaderOptions
  | SetHeaderOptions
  | NoOp
  | SetBusyStatus
  | SetCurrentError
  | SetCurrentMessage
  | SetCurrentBusinessModuleId
  | SetCurrentBusinessModuleIdSuccess
  | Login
  | LoginSuccess
  | LoginFail
  | LoginFailRedirect
  | SetSecurityInfo
  | Logout
  | LogoutStarted
  | LogoutSuccess
  | LogoutFail
  | LoadLookups
  | LoadLookupsSuccess
  | LoadAllLookups
  | LoadAllLookupsSuccess
  | LoadCptLookups
  | LoadCptLookupsSuccess
  | LoadDrgLookups
  | LoadDrgLookupsSuccess
  | LoadHcpcsLookups
  | LoadHcpcsLookupsSuccess
  | SetMemberSearchClientId
  | SetMemberSearchNameFilter
  | FindMembers
  | FindMembersSuccess
  | LoadClientNames
  | LoadClientNamesSuccess
  | ClearUser
  | LoadCategoryProcedures
  | LoadCategoryProcedureSuccess
  | ClearCategoryProcedures;
