import { ClientName } from 'carehub-api/models/client';
import { Cpt, Drg, Hcpcs } from 'carehub-api/models/common';
import { Member, MemberSearch } from 'carehub-api/models/member';
import { Procedure } from 'carehub-shared/components/controls/procedure';
import { SmartListResult } from 'carehub-shared/smartlist';
import { SecurityUser } from 'security-api/models/securityuser';
import { SharedActionTypes, SharedActions } from './shared.actions';

export interface User {
  displayName: string;
  userGuid: string;
  securityInfo: SecurityUser;
}

export interface LookupItem {
  id: number;
  type?: string;
  description: string;
  isActive: boolean;
  categoryId?: number;
}

export interface LookupItemString {
  id: string;
  description: string;
  isActive?: boolean;
}

export interface MemberSearchCriteria {
  search: string;
  clientId: string;
}

export interface HeaderOptions {
  iconNavigationLink: string;
  shouldShowModules: boolean;
  shouldShowSearch: boolean;
  shouldShowNotifications: boolean;
  shouldShowImpersonator: boolean;
  shouldShowHelp: boolean;
}

export interface SharedState {
  isBusy: boolean;
  currentError: string | null;
  currentErrorCode: number;
  currentMessage: { text: string; duration?: number } | null;

  currentUser: User | null;
  currentBusinessModuleId: number | null;

  smartState: number | null;
  lookups: Map<string, LookupItem[]>;
  cptLookups: Map<string, Cpt>;
  drgLookups: Map<string, Drg>;
  hcpcsLookups: Map<string, Hcpcs>;

  clientNames: ClientName[];

  foundMembers: MemberSearch[];
  loadedMembers: SmartListResult<Member>;
  memberSearchCriteria: MemberSearchCriteria;
  headerOptions: HeaderOptions;
  categoryProcedures: Procedure[];
}

const initialState: SharedState = {
  isBusy: false,
  currentError: null,
  currentErrorCode: null,
  currentMessage: null,

  currentUser: null,
  currentBusinessModuleId: null,

  smartState: null,
  lookups: new Map<string, LookupItem[]>(),
  cptLookups: new Map<string, Cpt>(),
  drgLookups: new Map<string, Drg>(),
  hcpcsLookups: new Map<string, Hcpcs>(),

  clientNames: null,

  foundMembers: null,
  loadedMembers: null,
  memberSearchCriteria: {
    search: null,
    clientId: null,
  },
  headerOptions: {
    iconNavigationLink: 'member-services-mgmt/dashboard',
    shouldShowHelp: true,
    shouldShowImpersonator: true,
    shouldShowModules: true,
    shouldShowNotifications: true,
    shouldShowSearch: true,
  },
  categoryProcedures: null,
};

export function reducer(
  state = initialState,
  action: SharedActions
): SharedState {
  switch (action.type) {
    case SharedActionTypes.SetHeaderOptions:
      return {
        ...state,
        headerOptions: {
          ...state.headerOptions,
          ...action.headerOptions,
        },
      };

    case SharedActionTypes.FindMembers:
      return {
        ...state,
        memberSearchCriteria: { ...action.payload },
      };

    case SharedActionTypes.FindMembersSuccess:
      return {
        ...state,
        foundMembers: action.results,
      };

    case SharedActionTypes.LoadAllLookupsSuccess:
      const all = new Map<string, LookupItem[]>();
      for (let item of action.payload.values) {
        all.set(item.type, item.describedItems);
      }

      return {
        ...state,
        lookups: all,
      };

    case SharedActionTypes.LoadLookupsSuccess:
      const replacements = new Map<string, LookupItem[]>(state.lookups);
      replacements.set(action.payload.type, action.payload.data);
      return {
        ...state,
        lookups: replacements,
      };

    case SharedActionTypes.LoadCptLookupsSuccess:
      const cptReplacements = new Map<string, Cpt>(state.cptLookups);
      cptReplacements.set(action.payload.code, action.payload.data);
      return {
        ...state,
        cptLookups: cptReplacements,
      };
    case SharedActionTypes.LoadDrgLookupsSuccess:
      const drgReplacements = new Map<string, Drg>(state.drgLookups);
      drgReplacements.set(action.payload.code, action.payload.data);
      return {
        ...state,
        drgLookups: drgReplacements,
      };
    case SharedActionTypes.LoadHcpcsLookupsSuccess:
      const hcpcsReplacements = new Map<string, Hcpcs>(state.hcpcsLookups);
      hcpcsReplacements.set(action.payload.code, action.payload.data);
      return {
        ...state,
        hcpcsLookups: hcpcsReplacements,
      };

    case SharedActionTypes.ClearUser:
      return {
        ...state,
        currentUser: null,
      };

    case SharedActionTypes.LogoutSuccess:
      return {
        ...state,
        currentError: null,
        currentErrorCode: null,
        currentUser: null,
      };

    case SharedActionTypes.LogoutFail:
      return {
        ...state,
        currentError: action.payload,
      };

    case SharedActionTypes.SetSecurityInfo:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          securityInfo: action.securityInfo,
        },
      };

    case SharedActionTypes.LoginSuccess:
      return {
        ...state,
        currentError: null,
        currentErrorCode: null,
        currentUser: {
          displayName: action.user.profile.name,
          userGuid: action.user.profile.sub,
          securityInfo: null,
        },
      };

    case SharedActionTypes.LoginFail:
      return {
        ...state,
        currentUser: null,
        currentError: action.payload,
      };

    case SharedActionTypes.SetBusyStatus:
      return {
        ...state,
        isBusy: action.payload,
      };

    case SharedActionTypes.SetMemberSearchClientId:
      return {
        ...state,
        memberSearchCriteria: {
          ...state.memberSearchCriteria,
          clientId: action.clientId,
        },
      };

    case SharedActionTypes.SetMemberSearchNameFilter:
      return {
        ...state,
        memberSearchCriteria: {
          ...state.memberSearchCriteria,
          search: action.payload,
        },
      };

    case SharedActionTypes.SetCurrentMessage:
      // if there's no text, just emit null
      const message = action.text ? { ...action } : null;
      return {
        ...state,
        currentMessage: message,
      };

    case SharedActionTypes.SetCurrentError:
      // todo: hook AppInsights trackException here.
      let error: string = null;
      let errorCode: number = null;

      if (action.payload) {
        if (action.payload.status || action.payload.status === 0) {
          errorCode = +action.payload.status;
          if (errorCode === 0) {
            errorCode = 500;
          }
        }

        if (typeof action.payload.error === 'string') {
          error = action.payload.error;
        } else if (action.payload.error) {
          const buffer = [];
          for (const key of Object.keys(action.payload.error)) {
            if (action.payload.message) {
              buffer.push(action.payload.message);
            }
            const iterable = action.payload.error[key];
            if (iterable && typeof iterable[Symbol.iterator] === 'function') {
              for (const validationError of iterable) {
                buffer.push(validationError);
              }
            }
          }

          error = buffer.join(' ');
        } else if (action.payload.message) {
          error = action.payload.message;
        } else {
          error = action.payload;
        }
      }

      if (error) {
        error = error.replace(
          '0 Unknown Error',
          'Could not find service endpoint.'
        );
      }
      return {
        ...state,
        currentError: error,
        currentErrorCode: errorCode,
        isBusy: false,
      };

    case SharedActionTypes.SetCurrentBusinessModuleIdSuccess:
      return {
        ...state,
        currentBusinessModuleId: action.payload,
      };

    case SharedActionTypes.LoadClientNamesSuccess:
      return {
        ...state,
        clientNames: action.payload,
      };
    case SharedActionTypes.LoadCategoryProcedureSuccess:
      return {
        ...state,
        categoryProcedures: action.payload,
      };
    case SharedActionTypes.ClearCategoryProcedures:
      return {
        ...state,
        categoryProcedures: null,
      };
    default:
      return state;
  }
}
