import { Injectable } from '@angular/core';
import { environment } from 'carehub-environment/environment';
import { slk } from 'survey-core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  static initialized = false;
  constructor(private authService: AuthService) {}

  async load(): Promise<void> {
    if (AppInitializerService.initialized === true) {
      return Promise.resolve();
    }

    if (environment.surveyJsKey && environment.surveyJsKey != '') {
      slk(environment.surveyJsKey);
    }

    AppInitializerService.initialized = true;
    this.authService.initialize();
  }
}
