// docs https://developer.niceincontact.com/Documentation/AgentSessionEvents?accordionId=AgentEvents
export enum EventTypes {
  AgentLeg = 'AgentLeg',
  AgentState = 'AgentState',
  AgentError = 'AgentError',
  CallContactEvent = 'CallContactEvent',
  ChatContactEvent = 'ChatContactEvent',
  ChatText = 'ChatText',
  AgentSessionStartEvent = 'AgentSessionStart',
}
