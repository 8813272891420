import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './shared.reducer';

const getSharedFeatureState = createFeatureSelector<SharedState>('shared');

export const getLookups = createSelector(
  getSharedFeatureState,
  (state) => state.lookups
);

export const getCptLookups = createSelector(
  getSharedFeatureState,
  (state) => state.cptLookups
);

export const getDrgLookups = createSelector(
  getSharedFeatureState,
  (state) => state.drgLookups
);

export const getHcpcsLookups = createSelector(
  getSharedFeatureState,
  (state) => state.hcpcsLookups
);

export const getSmartState = createSelector(
  getSharedFeatureState,
  (state) => state.smartState
);

export const getFoundMembers = createSelector(
  getSharedFeatureState,
  (state) => state.foundMembers
);

export const getLoadedMembers = createSelector(
  getSharedFeatureState,
  (state) => state.loadedMembers
);

export const getClientNames = createSelector(
  getSharedFeatureState,
  (state) => state.clientNames
);

export const getBusyStatus = createSelector(
  getSharedFeatureState,
  (state) => state.isBusy
);

export const getCurrentError = createSelector(
  getSharedFeatureState,
  (state) => state.currentError
);

export const getCurrentErrorCode = createSelector(
  getSharedFeatureState,
  (state) => state.currentErrorCode
);

export const getCurrentMessage = createSelector(
  getSharedFeatureState,
  (state) => state.currentMessage
);

export const getCurrentUser = createSelector(
  getSharedFeatureState,
  (state) => state?.currentUser
);

export const getHeaderOptions = createSelector(
  getSharedFeatureState,
  (state) => state?.headerOptions
);

export const getCategoryProcedures = createSelector(
  getSharedFeatureState,
  (state) => state?.categoryProcedures
);

// null or undefined is a sentinel value
// used to indicate that the permissions have not yet
// been loaded
export const getUserPermissions = createSelector(
  getSharedFeatureState,
  (state) =>
    state.currentUser &&
    state.currentUser.securityInfo &&
    state.currentUser.securityInfo.permissions
);

export const getCurrentBusinessModuleId = createSelector(
  getSharedFeatureState,
  (state) => state.currentBusinessModuleId
);
