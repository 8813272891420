import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MemberCasesApiService } from 'carehub-api/membercases-api.service';
import { Client } from 'carehub-api/models/client/client';
import { ClientName } from 'carehub-api/models/client/clientname';
import { CaseSummary } from 'carehub-api/models/membercase/casesummary';
import { State } from 'carehub-root/state/app.state';
import {
  PermissionScopes,
  checkPermission,
} from 'carehub-shared/directives/if-allowed.directive';
import { SmartListCriteria, SmartListResult } from 'carehub-shared/smartlist';
import {
  getClientNames,
  getCurrentBusinessModuleId,
  getCurrentUser,
  getFoundMembers,
} from 'carehub-shared/state';
import {
  FindMembers,
  LoadClientNames,
} from 'carehub-shared/state/shared.actions';
import { MemberSearchCriteria } from 'carehub-shared/state/shared.reducer';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';

// don't trigger searches too frequently
const MIN_SEARCH_LEN = 3;
const DEBOUNCE_TIME = 200;

interface MemberResult {
  memberId: string;
  clientId: string;
  displayName: string;
  dob: string | Date;
}

@Component({
  selector: 'ch-master-search',
  templateUrl: './master-search.component.html',
  styleUrls: ['./master-search.component.scss'],
})
export class MasterSearchComponent implements OnInit, AfterViewInit {
  private _memberFilter: ElementRef;

  showMemberFilter = false;

  @ViewChild('memberFilter')
  set memberFilter(value: ElementRef) {
    if (value) {
      this._memberFilter = value;
    }
  }

  private _clientFilter: ElementRef;

  @ViewChild('clientFilter')
  set clientFilter(value: ElementRef) {
    if (value) {
      this._clientFilter = value;
    }
  }

  private caseFilterInputEventObservable$: Observable<any>;

  private _caseFilter: ElementRef;

  @ViewChild('caseFilter')
  set caseFilter(value: ElementRef) {
    if (value) {
      this._caseFilter = value;
    }
  }

  foundMembers$: Observable<MemberResult[]>;
  filteredClients$: Observable<ClientName[]>;
  filteredCases: SmartListResult<CaseSummary>;

  memberSearchCriteria: MemberSearchCriteria = {
    search: '',
    clientId: '',
  };

  allowSearchResultsOverlay = false;
  clients: ClientName[];
  keydownHandler: Subscription;
  inputHandler: Subscription;
  searching: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private memberCaseService: MemberCasesApiService
  ) {}

  ngAfterViewInit(): void {
    if (this._clientFilter) {
      this.filteredClients$ = fromEvent(
        [this._clientFilter.nativeElement],
        'input'
      ).pipe(
        debounceTime(DEBOUNCE_TIME),
        map((event: KeyboardEvent) => {
          const target: any = event.target;
          let searchValue = target.value;
          if (searchValue) {
            searchValue = searchValue.toLowerCase();
          } else {
            this.memberSearchCriteria.clientId = null;
          }

          if (!this.clients) {
            return [];
          }

          return this.clients.filter(
            (client) =>
              client &&
              client.name &&
              client.name.toLowerCase().includes(searchValue)
          );
        })
      );

      fromEvent([this._clientFilter.nativeElement], 'keydown').subscribe(
        (e: KeyboardEvent) => {
          switch (e.key) {
            case 'Escape':
              this.allowSearchResultsOverlay = false;
              break;
          }
        }
      );
    }

    this.caseFilterInputEventObservable$ = fromEvent(
      this._caseFilter.nativeElement,
      'input'
    ).pipe(debounceTime(DEBOUNCE_TIME));

    let pending: Subscription = null;
    this.caseFilterInputEventObservable$.subscribe((event: any) => {
      const val = event.target.value;
      if (!val || val.length < MIN_SEARCH_LEN) {
        return;
      }

      const criteria = new SmartListCriteria();
      criteria.pageSize = 10;
      criteria.sortField = 'caseNumber';
      criteria.filter = val;

      // last-search-wins abort previous requests.
      if (pending) {
        pending.unsubscribe();
      }

      pending = this.memberCaseService
        .getFilteredCases(criteria)
        .subscribe((data: any) => {
          this.filteredCases = data;
        });
    });

    if (this._memberFilter) {
      this.keydownHandler = fromEvent(this._memberFilter.nativeElement, 'input')
        .pipe(
          debounceTime(DEBOUNCE_TIME),
          map((event: any) => event.target.value)
        )
        .subscribe((targetValue: string) => {
          if (targetValue.length < MIN_SEARCH_LEN) {
            return;
          }
          this.allowSearchResultsOverlay = true;

          this.memberSearchCriteria.search = targetValue.trim();

          // TODO(ross): kill this NGRX crap with FIRE
          this.store.dispatch(
            new FindMembers({ ...this.memberSearchCriteria })
          );
          this.searching = true;
        });

      this.inputHandler = fromEvent(
        [this._memberFilter.nativeElement],
        'keydown'
      )
        .pipe(filter((event: KeyboardEvent) => event.key === 'Escape'))
        .subscribe(() => {
          this.allowSearchResultsOverlay = false;
        });
    }
  }

  ngOnInit() {
    this.store.dispatch(new LoadClientNames());

    this.foundMembers$ = this.store.pipe(
      select(getFoundMembers),
      map((res) => {
        if (!res) return [];

        return res.map((member) => {
          const middle = member.preferredName
            ? ' "' + member.preferredName + '"'
            : '';

          return {
            memberId: member.memberId,
            clientId: member.clientId,
            dob: member.dob,
            displayName: `${member.firstName}${middle} ${member.lastName}`,
          };
        });

        // todo: format the name and stuff here one time
        // and make sure the hover thing is available
      })
    );
    this.foundMembers$.subscribe((m) => {
      // I know what I'm about son
      this.searching = false;
    });

    this.store.pipe(select(getCurrentUser)).subscribe((user) => {
      // the enIfReadAllowed thing was
      // really doing my head in.
      this.showMemberFilter = checkPermission(
        'Member',
        PermissionScopes.READ,
        user
      );
    });

    this.store.pipe(select(getClientNames)).subscribe((data) => {
      this.clients = data;
    });

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        // After 'navigating' anywhere we should be clear to
        // close the overlay search results window for the time being.
        this.allowSearchResultsOverlay = false;
      }
    });
  }

  getClientName(id: string) {
    if (this.clients) {
      const results = this.clients.filter((c) => c.clientId === id);
      if (results && results.length > 0) {
        return results[0].name;
      }
    }
    return '';
  }

  clientDisplay = (client: Client): string => client.name;

  onClientSelected(selectedItem: any) {
    this.memberSearchCriteria.clientId = selectedItem.option.value.clientId;

    if (this._clientFilter) {
      // #desperation_mode
      setTimeout(() => {
        this._clientFilter.nativeElement.blur();
        this._memberFilter.nativeElement.focus();
      }, 0);
    }
  }

  caseDisplay(value: any): string {
    return value.caseNumber;
  }

  onCaseSelected(selectedItem: any) {
    const memberCaseId = selectedItem.option.value.memberCaseId;
    const memberId = selectedItem.option.value.memberId;

    this.store
      .pipe(select(getCurrentBusinessModuleId))
      .pipe(take(1))
      .subscribe((data) => {
        let businessModule = 'member-services-mgmt';
        if (data === 2) {
          businessModule = 'finance/payments/reimbursements';
        }
        this.router.navigate([
          businessModule,
          'members',
          memberId,
          'cases',
          memberCaseId,
        ]);
      });

    this._caseFilter.nativeElement.value = '';
  }

  onViewAll() {
    this.router
      // ensure the component reloads
      .navigate(['/'], { skipLocationChange: true })
      .then(() =>
        this.router.navigate([
          'member-services-mgmt/members',
          'search',
          this.memberSearchCriteria.search,
        ])
      );
  }
}
