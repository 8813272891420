<div class="member-validation-info">
  <div class="member-info-panel">
    <div class="member-name">
      <div class="memberFullName">
        <label class="member-detail-label"> Full Name: </label>
        <span class="fullName">
          {{ memberToPreview?.firstName ? memberToPreview.firstName : '' }}
          {{
            memberToPreview?.middleName ? ' ' + memberToPreview?.middleName : ''
          }}
          {{ memberToPreview?.lastName ? ' ' + memberToPreview?.lastName : '' }}
        </span>
      </div>
      <div class="memberPreferredName">
        <label class="member-detail-label"> Preferred Name: </label>
        <span class="preferredName">
          {{
            memberToPreview?.preferredName
              ? memberToPreview.preferredName
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberDob">
        <label class="member-detail-label"> DOB: </label>
        <span class="dob">
          {{
            memberToPreview?.dob
              ? datePipe.transform(memberToPreview.dob) +
                ' (' +
                (memberToPreview.dob | date : 'MM/dd/yyyy' : '+0') +
                ')'
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberSsn">
        <label class="member-detail-label"> SSN: </label>
        <span class="ssn" *ngIf="canRevealSsn; else readonlySsn">
          <a href="javascript:void(0)" (click)="showSsn()">{{
            memberToPreviewSsn ? memberToPreviewSsn : '(N/A)'
          }}</a>
        </span>
        <ng-template #readonlySsn>
          {{ memberToPreviewSsn ? memberToPreviewSsn : '(N/A)' }}
        </ng-template>
      </div>
      <div class="memberGender">
        <label class="member-detail-label"> Gender: </label>
        <span class="gender">
          {{ memberToPreview?.gender ? memberToPreview.gender : '(N/A)' }}
        </span>
      </div>
    </div>
    <div class="member-ids">
      <div class="memberClientName">
        <label class="member-detail-label"> Client: </label>
        <span class="clientName">
          {{
            memberToPreview?.client?.name
              ? memberToPreview?.client.name
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberSurgeryPlusId">
        <label class="member-detail-label"> Member ID: </label>
        <span class="surgeryPlusMemberId">
          {{
            memberToPreview?.surgeryPlusMemberId
              ? memberToPreview?.surgeryPlusMemberId
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberEmployeeNumber">
        <label class="member-detail-label"> Employee Number: </label>
        <span class="surgeryPlusMemberId">
          {{
            memberToPreview?.employeeNumber
              ? memberToPreview?.employeeNumber
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberStatus">
        <label class="member-detail-label"> Status: </label>
        <span class="status">
          {{
            memberToPreview?.statusId
              ? (memberToPreview?.statusId | lookup : 'MemberStatuses' | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberPriority">
        <label class="member-detail-label"> Priority: </label>
        <span
          class="priority-vip"
          *ngIf="memberToPreview?.isVip && memberToPreview?.isVip; else notVip"
        >
          VIP
        </span>
        <ng-template #notVip>
          <span class="priority"> Standard </span>
        </ng-template>
      </div>
    </div>

    <div class="member-contact">
      <div class="memberPhone">
        <label class="member-detail-label"> Phone: </label>
        <span class="phone">
          {{
            memberToPreview?.demographic?.phone
              ? phonePipe.transform(memberToPreview?.demographic.phone)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberCellPhone">
        <label class="member-detail-label"> Cell Phone: </label>
        <span class="cellPhone">
          {{
            memberToPreview?.demographic?.cellPhone
              ? phonePipe.transform(memberToPreview?.demographic.cellPhone)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberOtherPhone">
        <label class="member-detail-label"> Other Phone: </label>
        <span class="otherPhone">
          {{
            memberToPreview?.demographic?.otherPhone
              ? phonePipe.transform(memberToPreview?.demographic.otherPhone)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberEmail">
        <label class="member-detail-label"> Email: </label>
        <span class="email">
          {{
            memberToPreview?.demographic?.email
              ? memberToPreview?.demographic.email
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberLanguage">
        <label class="member-detail-label"> Preferred Language: </label>
        <span class="language">
          {{
            memberToPreview?.languageId
              ? (memberToPreview?.languageId | lookup : 'Languages' | async)
              : '(N/A)'
          }}
        </span>
      </div>
    </div>
    <div class="member-demographics">
      <div class="memberDemographicType">
        <label class="member-detail-label"> Demographic Type: </label>
        <span class="demographicType">
          {{
            memberToPreview?.demographic?.demographicTypeId
              ? (memberToPreview?.demographic?.demographicTypeId
                | lookup : 'DemographicTypes'
                | async)
              : '(N/A)'
          }}
        </span>
      </div>
      <div class="memberAddress">
        <label class="member-detail-label"> Address: </label>
        <br />
        <span class="address">
          {{
            memberToPreview?.demographic?.address1
              ? memberToPreview?.demographic.address1
              : ''
          }}
        </span>
        <br *ngIf="memberToPreview?.demographic?.address2" />
        <span class="address">
          {{
            memberToPreview?.demographic?.address2
              ? memberToPreview?.demographic.address2
              : ''
          }}
        </span>
        <br />
        <span class="address">
          {{
            memberToPreview?.demographic?.city
              ? memberToPreview?.demographic.city + ','
              : ''
          }}
          {{
            memberToPreview?.demographic?.stateId
              ? (memberToPreview?.demographic.stateId
                | lookup : 'States'
                | async)
              : ''
          }}
          {{
            memberToPreview?.demographic?.zip
              ? memberToPreview?.demographic.zip
              : ''
          }}
          {{
            memberToPreview?.demographic?.plus4
              ? '-' + memberToPreview?.demographic.plus4
              : ''
          }}
        </span>
      </div>
    </div>
  </div>
  <button
    class="validate-member"
    mat-raised-button
    (click)="selectedMemberForValidation(memberToPreview)"
  >
    Confirm
  </button>
</div>
