import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'carehub-shared/components/base-component';
import { LookupService } from 'carehub-shared/services/lookup.service';
import { LookupTypes } from 'carehub-shared/services/models/lookup-types.enum';
import { LookupItem } from 'carehub-shared/state/shared.reducer';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

/** renders a multiselect with autocomplete for the specified enumeration */
@Component({
  selector: 'ch-lookup-multi-select',
  templateUrl: './lookup-multi-select.component.html',
  styleUrls: ['./lookup-multi-select.component.scss'],
})
export class LookupMultiSelectComponent
  extends BaseComponent
  implements OnInit
{
  private _allItems: LookupItem[] = [];
  public get allItems(): LookupItem[] {
    return this._allItems;
  }

  // Custom display function passed from parent component
  @Input() customDisplayFunction: (itemId: number) => string;

  // need to reload incase the ids were set before the options set was loaded
  @Input() public set allItems(items: LookupItem[]) {
    items = items ? items.sort((a, b) => a.id - b.id) : [];
    if (!_.isEqual(items, this._allItems)) {
      this._allItems = items;
      this.selectedChange.emit(this.selected);
    }
  }

  public get selected(): LookupItem[] {
    return this._selectedIds
      ? this._selectedIds
          .map((x) => this.allItems.find((y) => y.id === x))
          .filter((x) => !!x)
      : undefined;
  }
  // storing the selected by id allows for selected and all options to be set
  //  in any order, esp if the options are loading for a default value
  /** the select lookup items */
  @Input()
  public set selected(selected: LookupItem[]) {
    this.selectedIds = selected ? selected.map((x) => x.id) : undefined;
  }
  @Output()
  public selectedChange = new EventEmitter<LookupItem[]>();
  /** the selected lookup items' ids */
  /** the selected ids. since this can be set before items are loaded, retain ids */
  private _selectedIds: number[] = [];
  public get selectedIds(): number[] {
    return this._selectedIds ? this._selectedIds.slice() : undefined;
  }
  @Input()
  public set selectedIds(value: number[]) {
    let orderedIds = value
      ? value.filter((x) => x != null).sort((a, b) => a - b)
      : null;
    if (!_.isEqual(orderedIds, this._selectedIds)) {
      this._selectedIds = orderedIds;
      this.selectedIdsChange.emit(this.selectedIds);
      this.selectedChange.emit(this.selected);
    }
  }
  @Output()
  public selectedIdsChange = new EventEmitter<number[]>();

  private lookupType: LookupTypes;

  public get lookupTypeKey(): keyof typeof LookupTypes {
    return this.lookupType;
  }
  /** Required. the type of lookup to select from */
  @Input('lookupType')
  public set lookupTypeKey(value: keyof typeof LookupTypes) {
    this.lookupType =
      value && LookupTypes.hasOwnProperty(value)
        ? LookupTypes[value]
        : undefined;
  }
  /**
   * used to show only a subset of the lookup's members in the picker.
   * @param i the lookup item to include or exclude
   */
  @Input()
  filterLookupItems = (i: LookupItem): boolean => true;

  /** placeholder text */
  @Input() placeholder = '';
  @Input() disabled: boolean;
  /** style option to use the normal forms styling. defaults to false */
  @Input() useFormStyles = false;
  /** style option to remove some of the forms style padding, intended for use in tables */
  @Input() compact = false;

  constructor(private lookupService: LookupService) {
    super();
  }

  /** lifecycle hook called when this object is initiated */
  ngOnInit() {
    if (this.lookupType != undefined) {
      this.lookupService
        .getAllByType(this.lookupType)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (items: LookupItem[]) =>
            (this.allItems = items.filter(this.filterLookupItems))
        );
    }
  }

  /** lifecycle hook called when this object is disposed of */
  protected onDestroy(): void {
    this.selectedChange.complete();
    this.selectedIdsChange.complete();
  }

  /** method to get the user friendly representation of a lookup item */
  displayItem(item: LookupItem) {
    if (
      this.customDisplayFunction &&
      typeof this.customDisplayFunction === 'function'
    ) {
      return this.customDisplayFunction(item.id);
    } else {
      return item.description;
    }
  }

  sortBy(item1: LookupItem, item2: LookupItem): number {
    return item1.id - item2.id;
  }
}
