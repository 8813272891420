<span [formGroup]="formGroup">
  <mat-form-field
    class="common-fields"
    [class.hide-notifiers]="hideNotifiers"
    floatLabel="always"
    [appearance]="useV2Branding ? 'fill' : 'outline'"
    *ngIf="type !== 'date'"
  >
    <mat-label
      [hidden]="type === 'checkbox'"
      [ngClass]="{ 'mat-label-color': useV2Branding }"
      >{{ displayName }}
      <span class="ch-required-star" *ngIf="required">*</span>
    </mat-label>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'autocomplete'">
        <input
          type="text"
          matInput
          [matAutocomplete]="auto"
          [formControlName]="propertyName"
          (input)="autocompleteFilter($event.target.value)"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let entry of filteredAutocompleteEntries"
            [value]="entry[autocompleteFilterTerm]"
          >
            {{ entry[autocompleteFilterTerm] }}
          </mat-option>
        </mat-autocomplete>
      </ng-container>

      <input
        *ngSwitchCase="'phone'"
        [readonly]="isReadonly"
        matInput
        maxlength="24"
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
        [value]="getValue(propertyName)"
        enPhoneFormatter
      />
      <ng-container *ngSwitchCase="'time'">
        <input matInput class="invisible" [formControlName]="propertyName" />

        <!-- Render timepicker with time if it exists -->
        <ngx-timepicker-field
          *ngIf="getValue(propertyName) != ''"
          [formControlName]="propertyName"
          [defaultTime]="getValue(propertyName)"
          [controlOnly]="true"
          [disabled]="isReadonly"
          (timeChanged)="onChange($event)"
        >
        </ngx-timepicker-field>
        <!-- Otherwise don't supply a time so we get the placeholders. -->
        <ngx-timepicker-field
          *ngIf="getValue(propertyName) == ''"
          [formControlName]="propertyName"
          [controlOnly]="true"
          [disabled]="isReadonly"
          (timeChanged)="onChange($event)"
        >
        </ngx-timepicker-field>
      </ng-container>
      <ng-container *ngSwitchCase="'checkbox'">
        <input matInput placeholder="Input" style="display: none" />
        <mat-checkbox
          [id]="propertyName"
          (change)="onChange($event)"
          (blur)="onBlur($event)"
          [formControlName]="propertyName"
          [attr.disabled]="isReadonly"
          [checked]="getValue(propertyName)"
          >{{ displayName }}</mat-checkbox
        >
      </ng-container>
      <input
        *ngSwitchCase="'ssn'"
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
        [value]="getValue(propertyName) | ssn"
      />
      <textarea
        *ngSwitchCase="'multi'"
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [value]="getValue(propertyName)"
        [formControlName]="propertyName"
        matTextareaAutosize
        matAutosizeMinRows="5"
        matAutosizeMaxRows="10"
      ></textarea>
      <mat-select
        *ngSwitchCase="'lookup'"
        [attr.disabled]="isReadonly"
        matInput
        (selectionChange)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
        [value]="getValue(propertyName)"
      >
        <div *ngFor="let entry of entries$ | async">
          <!-- ? If the item is disabled, it will NOT show.
          ? If the item is disabled, but currently set, it is marked disabled
          ? So that the UI can render it, but you can not select it. -->
          <mat-option
            *ngIf="
              !filterInactive ||
              entry.isActive ||
              getValue(propertyName) == entry.id
            "
            [value]="getOptionValue(entry)"
            [disabled]="
              !isAllowed(entry) || (filterInactive && !entry.isActive)
            "
          >
            {{ getDropdownDisplay(entry) }}
          </mat-option>
        </div>
      </mat-select>
      <mat-select
        *ngSwitchCase="'picklist'"
        [attr.disabled]="isReadonly"
        matInput
        (selectionChange)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
        [value]="getValue(propertyName)"
      >
        <div *ngFor="let entry of entries$ | async">
          <!-- ? If the item is disabled, it will NOT show.
          ? If the item is disabled, but currently set, it is marked disabled
          ? So that the UI can render it, but you can not select it. -->
          <mat-option
            *ngIf="
              !filterInactive ||
              entry.isActive == null ||
              entry.isActive ||
              getValue(propertyName) == entry.id
            "
            [value]="getOptionValue(entry)"
            [disabled]="
              !isAllowed(entry) ||
              (filterInactive && !(entry.isActive == null || entry.isActive))
            "
          >
            {{ getDropdownDisplay(entry) }}
          </mat-option>
        </div>
      </mat-select>
      <input
        *ngSwitchCase="'currency'"
        class="currency"
        enCurrencyFormatter
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
        [maxlength]="maxlength"
      />
      <input
        *ngSwitchCase="'currency3'"
        class="currency"
        [enCurrencyFormatter]="{ roundToDecimal: 3 }"
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
      />
      <input
        *ngSwitchCase="'currency0'"
        class="currency"
        [enCurrencyFormatter]="{ roundToDecimal: 0 }"
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
      />
      <input
        *ngSwitchCase="'percentage'"
        class="currency"
        enPercentFormatter
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
      />
      <input
        *ngSwitchDefault
        [readonly]="isReadonly"
        matInput
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [formControlName]="propertyName"
        [attr.maxlength]="maxlength"
        [attr.type]="type === 'number' ? 'number' : null"
      />
    </ng-container>
    <mat-error
      *ngIf="isDisplayMessageAvailable() && displayMessage[propertyName]"
    >
      {{ displayMessage[propertyName] }}
    </mat-error>
  </mat-form-field>
  <!-- SMB: I had to separate this from the rest of the herd.  It was misbehaving i.e, the date field does not play well with others.  -->
  <mat-form-field
    id="date-field"
    *ngIf="type === 'date'"
    [class.hide-notifiers]="hideNotifiers"
    floatLabel="always"
    appearance="outline"
  >
    <mat-label [ngClass]="{ 'mat-label-color': useV2Branding }">
      {{ displayName }}
      <span class="ch-required-star" *ngIf="required">*</span></mat-label
    >
    <input
      autocomplete="off"
      [readonly]="isReadonly"
      [formGroup]="formGroup"
      matInput
      [matDatepicker]="datePicker"
      (blur)="onBlur($event)"
      (dateChange)="onChange($event)"
      [formControlName]="propertyName"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker" [disabled]="isReadonly">
    </mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error
      *ngIf="isDisplayMessageAvailable() && displayMessage[propertyName]"
    >
      {{ displayMessage[propertyName] }}
    </mat-error>
  </mat-form-field>
</span>
