import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ClientsApiService } from 'carehub-api/clients-api.service';
import { DocumentType as DocumentTypeEnum } from 'carehub-api/enums/enums/documenttype';
import { Status } from 'carehub-api/enums/facility/status';
import { DemographicType } from 'carehub-api/enums/member/demographictype';
import { MemberCasesApiService } from 'carehub-api/membercases-api.service';
import { Demographic } from 'carehub-api/models/member/demographic';
import { ProviderPlacement } from 'carehub-api/models/membercase/providerplacement';
import { FacilitySummary } from 'carehub-api/models/shared/facilitysummary';
import {
  ProvidersApiService,
  getFacilitiesByProviderId_Filter,
} from 'carehub-api/providers-api.service';
import { environment } from 'carehub-root/../environments/environment';
import { LookupService } from 'carehub-root/shared/services/lookup.service';
import { LookupTypes } from 'carehub-root/shared/services/models/lookup-types.enum';
import * as fromRoot from 'carehub-root/state/app.state';
import { SmartListCriteria, SmartListResult } from 'carehub-shared/smartlist';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { GeneratedDocumentDetails } from '../../document-gen-dialog.component';

@Component({
  selector: 'ch-blank-referral-form-maker',
  templateUrl: './blank-referral-form-maker.component.html',
})
export class BlankReferralFormMakerComponent {
  private _documentDetails: GeneratedDocumentDetails;
  protected unsubscribe$: Subject<void> = new Subject();
  facilities: FacilitySummary[];
  needsPriorAuthValue: boolean;

  @Input() set documentDetails(value: GeneratedDocumentDetails) {
    this._documentDetails = value;
    if (this._documentDetails != null) {
      this.setCompanyAndProductNames(this._documentDetails.documentTypeId);
      this.loadFormDetails();
    }
  }
  get documentDetails(): GeneratedDocumentDetails {
    return this._documentDetails;
  }

  providerName$: Observable<string>;
  procedureItemCategory$: Observable<string>;
  procedureItem$: Observable<string>;
  stylesheetUrl: SafeStyle;
  logoUrl: SafeUrl;

  isLanternVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  companyName: string;
  productName: string;

  constructor(
    private sanitizer: DomSanitizer,
    private lookupService: LookupService,
    private sharedStore: Store<fromRoot.State>,
    private memberCaseService: MemberCasesApiService,
    private providersService: ProvidersApiService,
    private clientsApi: ClientsApiService
  ) {
    const stylesheetUrl =
      environment.blobStorage + '/html2pdf/blank-referral-form.css';
    this.stylesheetUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(stylesheetUrl);

    const sPlusLogoUrl = this.sanitizer.bypassSecurityTrustUrl(
      environment.blobStorage + '/html2pdf/surgery-plus.png'
    );
    const lanternLogoUrl = this.sanitizer.bypassSecurityTrustUrl(
      environment.blobStorage + '/html2pdf/lantern-logo.svg'
    );

    this.isLanternVersion$.subscribe((isLanternVersion) => {
      if (isLanternVersion) {
        this.logoUrl = lanternLogoUrl;
      } else {
        this.logoUrl = sPlusLogoUrl;
      }
    });
  }

  setCompanyAndProductNames(docTypeId: number) {
    this.isLanternVersion$.next(
      docTypeId === DocumentTypeEnum.lanternReferralForm
    );
    switch (docTypeId) {
      case DocumentTypeEnum.blankReferralForm:
        this.companyName = 'Employer Direct Healthcare';
        this.productName = 'SurgeryPlus';
        break;
      case DocumentTypeEnum.lanternReferralForm:
        this.companyName = 'Lantern';
        this.productName = 'Lantern';
        break;
      default:
        this.companyName = '--';
        this.productName = '--';
        break;
    }
  }

  loadFormDetails() {
    //fetch procedureItemCategory name
    this.procedureItemCategory$ = this.getProcedureDetails(
      this.documentDetails.memberCase.requestedProcedureItemCategoryId,
      LookupTypes.ProcedureItemCategories,
      'No Procedure Category has been selected on the Case Header.'
    );
    //fetch procedureItem name
    this.procedureItem$ = this.getProcedureDetails(
      this.documentDetails.memberCase.requestedProcedureItemId,
      LookupTypes.ProcedureItems,
      'No Procedure Item has been selected on the Case Header.'
    );
    //fetch prior auth details
    this.loadPriorAuthDetails();
    //fetch provider details
    this.loadProviderDetails();
  }

  getProcedureDetails(
    id: number,
    lookupType: LookupTypes,
    errorMessage: string
  ) {
    if (id) {
      return this.lookupService.getDescriptionByTypeAndId(lookupType, id);
    } else {
      this.sharedStore.dispatch(
        new sharedActions.SetCurrentError(errorMessage)
      );
    }
  }

  loadPriorAuthDetails() {
    this.clientsApi
      .getProcedureCategories(this.documentDetails.member.memberId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        // Filter the results based on procedureCategoryId
        const filteredProcedure = data.find(
          (procedure) =>
            procedure.procedureCategoryId ===
            this.documentDetails.memberCase.requestedProcedureItemCategoryId
        );
        if (filteredProcedure) {
          this.needsPriorAuthValue = filteredProcedure.needsPriorAuth;
        } else {
          this.sharedStore.dispatch(
            new sharedActions.SetCurrentError('Procedure not found.')
          );
        }
      });
  }

  loadProviderDetails() {
    this.providerName$ = this.memberCaseService
      .getProviderRecsByCaseId(this.documentDetails.memberCase.memberCaseId)
      .pipe(
        map((ProviderPlacement: SmartListResult<ProviderPlacement>) => {
          const finalizedProviderRecs = ProviderPlacement.results.filter(
            (c) => c.finalizedDate
          );
          let found = false;
          if (finalizedProviderRecs && finalizedProviderRecs.length > 0) {
            const providerRec = finalizedProviderRecs[0].options.find(
              (c) =>
                c.providerRecOptionId ===
                finalizedProviderRecs[0].selectedProviderRecOptionId
            );
            if (providerRec) {
              found = true;
              //fetch approved facilities
              this.loadApprovedFacilities(providerRec.providerId);

              return `Dr. ${providerRec.firstName} ${providerRec.lastName}`;
            }
          }
          if (!found) {
            this.sharedStore.dispatch(
              new sharedActions.SetCurrentError(
                'No Provider has been finalized for this Case.'
              )
            );
            return '';
          }
        })
      );
  }

  loadApprovedFacilities(providerId: string) {
    if (providerId) {
      const criteria = new SmartListCriteria();
      const filter: Partial<getFacilitiesByProviderId_Filter> = {
        statuses: [
          Status.primarySurgeonFacility,
          Status.secondarySurgeonFacility,
        ],
      };
      criteria.sortField = 'name';
      criteria.pageSize = 5;
      this.providersService
        .getFacilitiesByProviderId(providerId, criteria, filter)
        .subscribe(
          (data) => {
            this.facilities = data.results;
          },
          (error) => {
            this.facilities = [];
            this.sharedStore.dispatch(new sharedActions.SetCurrentError(error));
          }
        );
    }
  }

  get blobStorageRoot() {
    return environment.blobStorage;
  }

  get phone(): string {
    const demographic = this.getDemographic();
    if (demographic) {
      return demographic.phoneExtension
        ? `${demographic.phone}x${demographic.phoneExtension}`
        : demographic.phone;
    } else {
      return '';
    }
  }

  get memberName(): string {
    return `${this.documentDetails.member.firstName} ${this.documentDetails.member.lastName}`;
  }

  get careAdvocateName(): string {
    return `${this.documentDetails.currentUser.firstName} ${this.documentDetails.currentUser.lastName}`;
  }

  get hubEmail(): string {
    if (this.documentDetails && this.documentDetails.client) {
      return `${this.documentDetails.client.email}`;
    } else {
      return '';
    }
  }

  get hubPhoneNumber(): string {
    if (this.documentDetails && this.documentDetails.client) {
      return `${this.documentDetails.client.phone}`;
    } else {
      return '';
    }
  }

  get address$(): Observable<string> {
    const demographic = this.getDemographic();
    if (demographic) {
      return this.lookupService
        .getDescriptionByTypeAndId(LookupTypes.States, demographic.stateId)
        .pipe(
          first(),
          map((state) => {
            const buffer = [];
            buffer.push(demographic.address1);
            if (
              demographic.address2 &&
              demographic.address2.trim().length > 0
            ) {
              buffer.push(', ');
              buffer.push(demographic.address2);
            }
            buffer.push(', ');
            buffer.push(demographic.city);
            buffer.push(', ');
            buffer.push(state);
            buffer.push(` ${demographic.zip}`);

            return buffer.join('');
          })
        );
    } else {
      return of('');
    }
  }

  get email(): string {
    const demographic = this.getDemographic();
    if (demographic) {
      return demographic.email;
    } else {
      return '';
    }
  }

  private getDemographic(): Demographic {
    let demographic: Demographic;
    if (
      this.documentDetails &&
      this.documentDetails.demographics &&
      this.documentDetails.demographics.length > 0
    ) {
      demographic = this.documentDetails.demographics.find(
        (c) => c.demographicTypeId === <number>DemographicType.alternate
      );
      if (!demographic) {
        demographic = this.documentDetails.demographics[0];
      }
    }

    return demographic;
  }
}
