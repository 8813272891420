import { NgModule } from '@angular/core';
import { MaterialModule } from 'carehub-shared/material.module';
import { SharedModule } from 'carehub-shared/shared.module';
import { PlanAuditComponent } from './components/plan-audit/plan.audit.component';
import { RejectPlanDialogComponent } from './components/plan-audit/reject-plan-dialog/reject-plan-dialog.component';
import { PlanBenefitStructureComponent } from './components/plan-creation-tabs/plan-benefit-structure/plan-benefit-structure.component';
import { PlanCoverageComponent } from './components/plan-creation-tabs/plan-coverage/plan-coverage.component';
import { PlanDetailsComponent } from './components/plan-creation-tabs/plan-details/plan-details.component';
import { PlanExemptionComponent } from './components/plan-creation-tabs/plan-exemption/plan-exemption.component';
import { PlanProceduresComponent } from './components/plan-creation-tabs/plan-procedures/plan-procedures.component';
import { PlanRewardsComponent } from './components/plan-creation-tabs/plan-rewards/plan-rewards.component';
import { PlanUtilizationMgmtComponent } from './components/plan-creation-tabs/plan-utilization-mgmt/plan-utilization-mgmt.component';
import { PlanCreationHeaderComponent } from './components/plan-creation-wizard/header/plan-creation-header.component';
import { PlanCreationWizardComponent } from './components/plan-creation-wizard/plan-creation-wizard.component';
import { PlanRenewalComponent } from './components/plan-renewal/plan.renewal.component';

@NgModule({
  imports: [SharedModule, MaterialModule],
  entryComponents: [
    PlanCreationWizardComponent,
    PlanDetailsComponent,
    PlanCoverageComponent,
    PlanCreationHeaderComponent,
    PlanProceduresComponent,
    PlanBenefitStructureComponent,
  ],
  declarations: [
    PlanCreationWizardComponent,
    PlanDetailsComponent,
    PlanCoverageComponent,
    PlanCreationHeaderComponent,
    PlanProceduresComponent,
    PlanUtilizationMgmtComponent,
    PlanBenefitStructureComponent,
    PlanRewardsComponent,
    PlanAuditComponent,
    RejectPlanDialogComponent,
    PlanRenewalComponent,
    PlanExemptionComponent,
  ],
  providers: [],
})
export class MemberPlansSubModule {}
