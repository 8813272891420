import { Observable } from 'rxjs';
import { IconDetails } from '../icon-details';

export interface ControlDefinition {
  type: 'radio-button' | 'checkbox' | 'auto-lookup-multi-select' | 'search';
  name: string;
  isDisabled?: (row: any) => boolean;
}

export interface BasicControlDefinition extends ControlDefinition {
  // should return a value based on the control type
  bindingDataSource?: (row: any) => any;
  // optional: allows the inline row control to write the value back to the row
  setValue?: (row: any, val: any) => void;
}

export abstract class LookupMultiSelectDefinition implements ControlDefinition {
  type: 'auto-lookup-multi-select';
  name: string = '';
  abstract placeholder: string;
  abstract lookupType: string;
  abstract selectedIds: (row: any) => number[];
  abstract updatedSelectedIds: (row: any, newValue: number[]) => void;
}

// old and busted
export interface ColumnDetails {
  // !important: this is assumed to be the name
  // of the property when sorting, and must exist on the target type.
  columnDef: string;
  header: string;
  sortable?: boolean;
  width?: string;
  cssHeaderText?: string;
  headerIcon?: IconDetails;

  /**
   * todo: map these instead of the by-value logic in
   * @see SmartListGridComponent
   */
  type?: 'string' | 'icon' | 'number' | 'control' | 'currency' | 'array';
  controlDef?: ControlDefinition | BasicControlDefinition;

  // these have any type rather than just string...
  // because we apparently have some kind of sub-cell thing going on.
  cell: (item: any, index?: number, length?: number) => any | Observable<any>;

  /** for cell values that resolve into long string values, truncates at the specified length and displays the full value as a tool tip on hover. only works for non-async cell results */
  cellValueMaxLength?: number;

  cssTextClassResolver?: (item: any) => string;

  /** optional override for the cell background color */
  backgroundColor?: string;
  toolTip?: string;
  iconSets?: {
    [key: string]: IconDetails;
    default?: IconDetails;
  }[];
  alignText?: ColumnTextAlign;
}

export type TypedColumn<T> = {
  readonly columnDef: string;
  readonly header: string;

  readonly cell: (
    item: T,
    index?: number,
    length?: number
  ) => any | Observable<any>;
  readonly cssTextClassResolver?: (item: T) => string;

  /*
  |-------------------|
  |  optional fields  |
  |-------------------|
  */

  // 1fr should be the default
  readonly width?: string;

  /**
   * todo: map these instead of the by-value logic in
   * @see SmartListGridComponent
   */
  readonly type?:
    | 'string'
    | 'icon'
    | 'number'
    | 'control'
    | 'currency'
    | 'array';
  readonly controlDef?: ControlDefinition | BasicControlDefinition;

  /**
   * for cell values that resolve into long string values,
   * truncates at the specified length and displays the full value as a tool tip on hover.
   * only works for non-async cell results
   * */
  readonly cellValueMaxLength?: number;

  /** optional override for the cell background color */
  readonly backgroundColor?: string;
  readonly toolTip?: string;
  readonly iconSets?: {
    [key: string]: IconDetails;
    default?: IconDetails;
  }[];
  readonly alignText?: ColumnTextAlign;
};

// the typescript error might show up strangely
// but if you're attempting to sort your columnDef
// must match the name of a property
export type SortableColumn<T> = {
  readonly columnDef: keyof T & string;
  readonly sortable: true;
  readonly cell: (
    item: T,
    index?: number,
    length?: number
  ) => any | Observable<any>;
  readonly cssTextClassResolver?: (item: T) => string;
} & TypedColumn<T>;

// new hotness
export type Column<T> = SortableColumn<T> | TypedColumn<T>;

export enum ColumnTextAlign {
  Left,
  Right,
  Center,
}
